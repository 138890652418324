import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { useEffect, useState } from 'react';

import { format } from 'date-fns';

function CustomAxis({ x, y, payload }: any) {
  const date = format(new Date(payload.value * 1000), 'd');
  return (
    <g
      transform={`translate(${x},${y})`}
      className="text-xs text-gray-500 md:text-sm"
    >
      <text x={0} y={0} dy={10} textAnchor="end" fill="currentColor">
        {date}
      </text>
    </g>
  );
}
const numberAbbr = (number: any) => {
  return number;
};
type LiquidityChartProps = {
  LiquidityData: any;
  setPrice: any;
  price: any;
  name: any;
  symbol: any;
};
export default function LiquidityChart({
  LiquidityData,
  setPrice,
  price,
  name,
  symbol,
}: LiquidityChartProps) {
  let [date, setDate] = useState(LiquidityData[0].last_updated_time);
  let [liquidity, setLiquidity] = useState(LiquidityData[0].price);
  const formattedDate = format(new Date(date * 1000), 'MMMM d, yyyy');
  const dailyLiquidity = numberAbbr(liquidity);
  useEffect(() => {
    setDate(LiquidityData[0].last_updated_time);
    setLiquidity(LiquidityData[0].price);
  }, [LiquidityData]);
  const handleprice = (e: any) => {
    setPrice(e.target.value);
  };
  return (
    <>
      {LiquidityData.length > 0 && (
        <div className="rounded-lg bg-white p-6 shadow-card dark:bg-light-dark sm:p-8">
          <h3 className="mb-1.5 flex items-center justify-between text-sm  tracking-wider text-gray-600 dark:text-gray-400 sm:mb-2 sm:text-base">

            {name} Price

            <div className="flex flex-wrap">
              <div className="mx-2">
                <input
                  className="peer sr-only"
                  checked={'usd' == price}
                  type="radio"
                  value="usd"
                  name="pricetype"
                  onChange={handleprice}
                  id="USD"
                />
                <label
                  className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg border border-gray-300 bg-white text-sm text-gray-600 hover:bg-gray-50 focus:outline-none peer-checked:bg-gray-400 peer-checked:text-white dark:text-gray-400"
                  htmlFor="USD"
                >
                  USD
                </label>
              </div>
              <div className="mx-2">
                <input
                  className="peer sr-only"
                  checked={'inr' == price}
                  type="radio"
                  value="inr"
                  name="pricetype"
                  onChange={handleprice}
                  id="INR"
                />
                <label
                  className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg border border-gray-300 bg-white text-sm text-gray-600 hover:bg-gray-50 focus:outline-none peer-checked:bg-gray-400 peer-checked:text-white dark:text-gray-400"
                  htmlFor="INR"
                >
                  INR
                </label>
              </div>
              <div className="mx-2">
                <input
                  className="peer sr-only"
                  checked={'eur' == price}
                  type="radio"
                  value="eur"
                  name="pricetype"
                  onChange={handleprice}
                  id="EUR"
                />
                <label
                  className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg border border-gray-300 bg-white text-sm text-gray-600 hover:bg-gray-50 focus:outline-none peer-checked:bg-gray-400 peer-checked:text-white dark:text-gray-400"
                  htmlFor="EUR"
                >
                  EUR
                </label>
              </div>
            </div>
          </h3>
          <div className="mb-1 flex items-center text-base font-medium text-gray-900 dark:text-white sm:text-xl">
            {dailyLiquidity}{' '}
            {price == 'usd' ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="mx-2 h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            ) : price == 'inr' ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="mx-2 h-6  w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="mx-2 h-6  w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            )}
          </div>
          <div className="text-xs text-gray-600 dark:text-gray-400 sm:text-sm">
            {formattedDate}
          </div>
          <div className="mt-5 h-64 sm:mt-8 2xl:h-72 3xl:h-[340px] 4xl:h-[480px]">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={LiquidityData}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
                onMouseMove={(data) => {
                  if (data.isTooltipActive) {
                    setDate(
                      data.activePayload &&
                        data.activePayload[0].payload.last_updated_time
                    );
                    setLiquidity(
                      data.activePayload && data.activePayload[0].payload.price
                    );
                  }
                }}
              >
                <defs>
                  <linearGradient
                    id="liquidity-gradient"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="5%" stopColor="#bc9aff" stopOpacity={0.5} />
                    <stop offset="100%" stopColor="#7645D9" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="last_updated_time"
                  tickLine={false}
                  axisLine={false}
                  tick={<CustomAxis x={'4'} />}
                  interval={0}
                  tickMargin={5}
                />
                <Tooltip content={<></>} cursor={{ stroke: '#7645D9' }} />
                <Area
                  type="linear"
                  dataKey="price"
                  stroke="#7645D9"
                  strokeWidth={1.5}
                  fill="url(#liquidity-gradient)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </>
  );
}
