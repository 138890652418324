import Image from 'next/image';

const NftImage = () => {
  return (
    <div className="mt-3 flex">
      <div className="mr-2 border-0">
        <Image
          width={440}
          height={280}
          src={'/rigel.png'}
          alt="r"
          className="rounded-3xl"
        />
      </div>
      <div className="mr-2">
        <Image
          width={440}
          height={280}
          src={'/sirius.png'}
          alt="sr"
          className="rounded-3xl"
        />
      </div>
      <div className="mr-2">
        <Image
          width={440}
          height={280}
          src={'/vega.png'}
          alt="v"
          className="rounded-3xl"
        />
      </div>
      <div className="">
        <Image
          width={440}
          height={280}
          src={'/planet.png'}
          alt="planet"
          className="rounded-3xl"
        />
      </div>
    </div>
  );
};

export default NftImage;
