// eslint-disable-next-line react-hooks/exhaustive-deps

import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import React, { useContext, useEffect, useState } from 'react';

import DashboardLayout from '@/layouts/_dashboard';
import LiquidityChart from '@/components/ui/chats/liquidity-chart';
import type { NextPageWithLayout } from '@/types';
import { NextSeo } from 'next-seo';
import NftImage from './nftImage';
import TopCurrencyTable from '@/components/top-currency/currency-table';
import { TopTokenList } from '@/lib/Data/TopToken';
import TransactionHistoryTable from '@/components/transaction/transaction-history';
import VolumeChart from '@/components/ui/chats/volume-chart';
import { WalletContext } from '@/lib/hooks/use-connect';
import axios from 'axios';

const TokenInfoApi: string | undefined =
  process.env.NEXT_PUBLIC_TOKEN_INFO_API || '';

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
  };
};

const HomePage: NextPageWithLayout<
  InferGetStaticPropsType<typeof getStaticProps>
> = () => {
  const {} = useContext(WalletContext);
  let [price, setPrice] = useState('usd');
  let [LiquidityData, setLiquidityData] = useState<any[]>([]);
  const token = React.useMemo(() => TopTokenList, []);
  const [tokenInfo, setTokenInfo] = useState(token[0]);
  useEffect(() => {
    fetchTokenPrice(tokenInfo.contractAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price, tokenInfo]);
  const fetchTokenPrice = async (contractAddress: any) => {
    await axios
      .get(
        `${TokenInfoApi}/token_infos?contract_address=${contractAddress.toLowerCase()}`
      )
      .then(async (val: any) => {
        let tokenPrice = await Promise.all(
          val.data.map((val1: any, index: number) => {
            return price == 'usd'
              ? val1.contract_balance.usd
              : price == 'inr'
              ? val1.contract_balance.inr
              : val1.contract_balance.eur;
          })
        );
        setLiquidityData(tokenPrice);
      });
  };
  return (
    <>
      <NextSeo
        title="Cepheus"
        description="Cepheus is a protocol that is built with the new DNFT primitive in mind. DNFTs give you rewards and access to various token utilities."
      />
      <div className="mb-2 text-center text-xs font-bold tracking-widest md:mb-0 md:text-lg xl:text-lg">
        <h1 data-testid="AstrolistWarning ">
          Our{' '}
          <span className="bg-gradient-to-r from-[#E84AD2] to-cyan-400 bg-clip-text text-transparent">
            DNFTs
          </span>{' '}
        </h1>
      </div>
      <NftImage />
      <div id="chart" className="mt-8 grid gap-6 sm:my-10 md:grid-cols-2">
        {LiquidityData.length > 0 && (
          <>
            <LiquidityChart
              LiquidityData={LiquidityData}
              setPrice={setPrice}
              price={price}
              name={tokenInfo.name}
              symbol={tokenInfo.symbol}
            />
            <VolumeChart
              LiquidityData={LiquidityData}
              setPrice={setPrice}
              name={tokenInfo.name}
              symbol={tokenInfo.symbol}
            />
          </>
        )}
      </div>
      <div className="my-8 sm:my-10">
        <TopCurrencyTable setTokenInfo={setTokenInfo} />
      </div>

      <div className="flex">
        <div className="w-full ">
          <TransactionHistoryTable />
        </div>
      </div>
    </>
  );
};

HomePage.getLayout = function getLayout(page) {
  return <DashboardLayout>{page}</DashboardLayout>;
};

export default HomePage;
