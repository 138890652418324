import React, { useContext, useEffect, useState } from 'react';

import AnchorLink from '../ui/links/anchor-link';
import { ArrowLinkIcon } from '../icons/arrow-link-icon';
import Button from '@/components/ui/button';
import { LongArrowLeft } from '@/components/icons/long-arrow-left';
import { LongArrowRight } from '@/components/icons/long-arrow-right';
import Scrollbar from '@/components/ui/scrollbar';
import { WalletContext } from '@/lib/hooks/use-connect';
import axios from 'axios';
import { ethers } from 'ethers';

const TransactionContractAdress: string | undefined =
  process.env.NEXT_PUBLIC_TRANSACTION_CONTRACTADDRESS || '';
const TRANSACTIONAPI: string | undefined =
  process.env.NEXT_PUBLIC_CONTRACT_TRANSACTIONAPI || '';
const ALCHEMYKEY: any = process.env.NEXT_PUBLIC_ALCHEMY_KEY || '';
const transactionHash_baseURL: string | undefined =
  process.env.NEXT_PUBLIC_TRANSACTIONHASH_BASERURL || '';
export default function TransactionHistoryTable() {
  const { address } = useContext(WalletContext);
  const [tableData, setTableData] = useState<any[]>([]);
  const [transactionData, setTransactionData] = useState<any[]>([]);
  const [numPagination, setNumPagination] = useState<number>(0);
  useEffect(() => {
    let randomNum = Math.round(Math.random() * 10);
    if (address) {
      fetchDataT(address, randomNum);
    } else {
      setTransactionData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);
  const column = ['Id', 'Data', 'From', 'To', 'Amount', 'Explore'];
  //fetch user and contract transaction data
  const fetchDataT = async (owneraddress: string, randomNum: number) => {
    let apiKeys = JSON.parse(ALCHEMYKEY);
    const arr: any = [];
    const options: any = {
      method: 'POST',
      url: `${TRANSACTIONAPI}/${apiKeys[randomNum]}`,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      data: {
        id: 1,
        jsonrpc: '2.0',
        method: 'alchemy_getAssetTransfers',
        params: [
          {
            contractAddresses: [TransactionContractAdress],
            category: ['erc20'],
            withMetadata: true,
            excludeZeroValue: true,
            fromAddress: owneraddress,
            order: 'desc',
          },
        ],
      },
    };
    await axios
      .request(options)
      .then(function (response) {
        response.data.result.transfers?.forEach((val: any) => {
          arr.push({
            transactionHash: val.hash,
            transactionDate: val.metadata.blockTimestamp,
            transactionFrom: val.from,
            transactionTo: val.to,
            transactionValue: val.rawContract.value,
            transactionDecimal: val.rawContract.decimal,
          });
        });
      })
      .catch((err) => {
        let randomNum = Math.round(Math.random() * 10);
        fetchDataT(address, randomNum);
      });
    setTableData(arr);
    tablePagination(arr, 0);
    setNumPagination(0);
  };
  const tablePagination = (data: any, num: any) => {
    let arr = [];

    for (let i = num * 5; i < num * 5 + 5; i++) {
      if (i < data.length) arr.push(data[i]);
    }
    setTransactionData(arr);
  };
  return (
    <div>
      <div className="rounded-tl-lg rounded-tr-lg bg-white px-4 pt-6 dark:bg-light-dark md:px-8 md:pt-8">
        <div className="flex flex-col items-center justify-between border-b border-dashed border-gray-200 pb-5 dark:border-gray-700 md:flex-row">
          <h2 className="mb-3 shrink-0 text-lg font-medium  text-black dark:text-white sm:text-xl md:mb-0 md:text-2xl">
            Transaction History
          </h2>
        </div>
      </div>
      {transactionData.length > 0 ? (
        <>
          <div className="-mx-0.5">
            <Scrollbar style={{ width: '100%' }} autoHide="never">
              <div className="px-0.5">
                <table className="transaction-table w-full border-separate border-0">
                  <thead className="text-sm text-gray-500 dark:text-gray-300">
                    <tr>
                      {column.map((val: any, index: any) => {
                        return (
                          <th
                            key={index}
                            className="group  bg-white px-2 py-5 font-normal first:rounded-bl-lg last:rounded-br-lg ltr:first:pl-8 ltr:last:pr-8 rtl:first:pr-8 rtl:last:pl-8 dark:bg-light-dark md:px-4"
                          >
                            <div className="flex items-center">
                              <span className="ltr:ml-1 rtl:mr-1">{val}</span>
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody className="text-xs font-medium text-gray-900 dark:text-white 3xl:text-sm">
                    <>
                      {transactionData.map((val: any, id: any) => {
                        return (
                          <tr
                            key={id}
                            className="mb-3 items-center rounded-lg bg-white  shadow-card last:mb-0 dark:bg-light-dark"
                          >
                            <td className="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
                              {numPagination * 5 + id + 1}
                            </td>
                            <td className="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
                              {new Date(val.transactionDate).toLocaleString(
                                'en-US',
                                {
                                  month: 'numeric',
                                  day: 'numeric',
                                  year: 'numeric',
                                }
                              )}
                            </td>
                            <td className="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
                              {`${val.transactionFrom.slice(
                                0,
                                5
                              )}...${val.transactionFrom.slice(38, 42)}`}
                            </td>
                            <td className="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
                              {`${val.transactionTo.slice(
                                0,
                                5
                              )}...${val.transactionTo.slice(38, 42)}`}
                            </td>
                            <td className="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
                              {parseFloat(
                                ethers.utils.formatUnits(
                                  val.transactionValue,
                                  val.transactionDecimal
                                )
                              ).toFixed(2)}
                            </td>
                            <td className="px-2 py-4 tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
                              <AnchorLink
                                href={`${transactionHash_baseURL}/${val.transactionHash}`}
                                target="_blank"
                                className="mt-1.5 inline-flex items-center text-sm -tracking-wider text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white xl:mt-2.5"
                              >
                                <ArrowLinkIcon className="h-3 w-3 ltr:ml-2 rtl:mr-2" />
                              </AnchorLink>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  </tbody>
                </table>
              </div>
            </Scrollbar>
          </div>
        </>
      ) : (
        <div className="mb-3 rounded-lg bg-white  shadow-card last:mb-0 dark:bg-light-dark">
          <div className="px-2 py-4 text-center tracking-[1px] ltr:first:pl-4 ltr:last:pr-4 rtl:first:pr-8 rtl:last:pl-8 md:px-4 md:py-6 md:ltr:first:pl-8 md:ltr:last:pr-8 3xl:py-5">
            {address
              ? 'No transaction found'
              : 'No data available, please connect your wallet .'}
          </div>
        </div>
      )}
      <div className="mt-3 flex items-center justify-center rounded-lg bg-white px-5 py-4 text-sm shadow-card dark:bg-light-dark lg:py-6">
        <div className="flex items-center gap-5">
          <Button
            onClick={() => {
              setNumPagination(numPagination - 1);
              tablePagination(tableData, numPagination - 1);
            }}
            disabled={!(numPagination - 1 >= 0)}
            title="Previous"
            shape="circle"
            variant="transparent"
            size="small"
            className="text-gray-700 disabled:text-gray-400 dark:text-white disabled:dark:text-gray-400"
          >
            <LongArrowLeft className="h-auto w-4 rtl:rotate-180" />
          </Button>
          <div>
            Page{' '}
            <strong className="font-semibold">
              {/* {pageIndex + 1} of {pageOptions.length} */}
            </strong>{' '}
          </div>
          <Button
            onClick={() => {
              setNumPagination(numPagination + 1);
              tablePagination(tableData, numPagination + 1);
            }}
            disabled={!((numPagination + 1) * 5 < tableData.length)}
            title="Next"
            shape="circle"
            variant="transparent"
            size="small"
            className="text-gray-700 disabled:text-gray-400 dark:text-white disabled:dark:text-gray-400"
          >
            <LongArrowRight className="h-auto w-4 rtl:rotate-180 " />
          </Button>
        </div>
      </div>
    </div>
  );
}
